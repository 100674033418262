import { getMessaging, getToken } from '@firebase/messaging';
import { initializeApp } from '@firebase/app';
import { getStorage } from '@firebase/storage';
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
  projectId: 'ropha-telemedicine',
  apiKey: 'AIzaSyCvTjTPUagW-i-c-HM7MWtuodP3lY2VpvA',
  authDomain: 'ropha-telemedicine.firebaseapp.com',
  storageBucket: 'ropha-telemedicine.appspot.com',
  messagingSenderId: '606103963274',
  appId: '1:606103963274:web:6f8d1aab2ffbacb3018f73',
  measurementId: 'G-CXTHP7YQ82'
};

const firebaseApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(firebaseApp);

export const db = getFirestore(firebaseApp);

export const storage = getStorage();

export const getFCMToken = () => getToken(messaging);
