import { FC, useEffect } from 'react';
import { setupNotifications } from '@web/utils/notification.utils';
import { toast } from 'react-toastify';
import { api, useApi } from '@web/hooks/useApi';
import { getNotificationItemProps } from '@web/utils/functions';
import { useNavigate } from 'react-router-dom';
import { getFCMToken } from '@web/utils/firebase.utils';

export const withNotifications = (WrappedComponent: FC) => {
  return () => {
    const [updateToken] = useApi(api.notifications.updateToken);
    const navigate = useNavigate();

    useEffect(() => {
      if (Notification.permission === 'default') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            new Notification('Hi there 👋!', {
              body: 'We promise you won\'t miss any updates'
            });
            getFCMToken()
              .then(token => updateToken({ token }))
              .catch(e => console.error(e));
          } else if (permission === 'denied') {
            toast.warning('You have chosen to disable notifications. We will not be able to send realtime updates in the background');
          } else {
            toast.warning('We may not be able to actively notify you on realtime updates');
          }
        });
      }

      setupNotifications((type, payload, priority) => {
        const props = getNotificationItemProps({ type, payload, createdAt: new Date().toISOString() });
        toast.info(props.body, {
          autoClose: priority === 'HIGH' ? false : 5000,
          onClick: () => navigate(props.url)
        });
      });
    }, []);

    return <WrappedComponent />;
  };
};
