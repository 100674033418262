import { onMessage } from '@firebase/messaging';
import { NotificationEntity } from '@web/gen/api';
import { messaging } from '@web/utils/firebase.utils';


export const setupNotifications = async (
  cb: (type: NotificationEntity['type'], _: NotificationEntity['payload'], $: NotificationEntity['priority']) => void
) => {
  try {
    onMessage(messaging, (payload) => {
      const data = payload.data as any;
      console.log('Message:', data);
      cb(data.type, JSON.parse(data.payload), data.priority);
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
