import { AntdIcon } from './AntdIcon';

function FileIcon({ type }: { type: string | undefined }) {
  return <AntdIcon name={fileIcon(type)} />;
}

function fileIcon(type: string | undefined) {
  if (!type) return 'FileUnknownOutlined';
  switch (type.toLowerCase()) {
    case 'pdf':
      return 'FilePdfOutlined';
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'svg':
    case 'gif':
    case 'web':
      return 'FileImageOutlined';
    case 'zip':
      return 'FileZipOutlined';
    case 'dcm':
      return 'FileProtectOutlined';
    default:
      return 'FileOutlined';
  }
}

export default FileIcon;
